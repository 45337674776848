import { Flex, Text, useMediaQuery, Button } from "@chakra-ui/react";
import { Header } from "../components/header";
import "../styles/animation.css";
import { InfoBox } from "../components/info-box";

export const Homepage = () => {
  const isMobile = useMediaQuery("(max-width: 1200px)")[0];
  const handleSendClick = () => {
    const gmailComposeUrl = `https://mail.google.com/mail/u/0/?tab=rm&ogbl#inbox?compose=GTvVlcSDbttwxbzCrXdvBLHVVgmGnsLgSzTgDwGbRpFczPzdkLJVwkDqsNLrqNKFcMTbFvgdJxlzl`;

    window.open(gmailComposeUrl, "_blank");
  };

  return (
    <Flex
      bgColor="#ecf2ff"
      w="100vw"
      minH="100vh"
      flexDir="column"
      p="5"
      alignItems="center"
      gap="10"
    >
      <Header />
      <Flex p="4">
        <Text
          fontFamily="'Caveat', cursive"
          fontSize={isMobile ? "1.2rem" : "2.3rem"}
          className="typing-text"
        >
          uniway mentörleri ile üniverisite sınavına hazırlan!
        </Text>
      </Flex>
      <Flex gap="6" flexDir={isMobile ? "column" : "row"}>
        <InfoBox text="Sınava hazırlık sürecinde hedefe yönelik destek" />

        <InfoBox text="Erişilebilir Rehberlik" />

        <InfoBox text="Tercih Döneminde Üniversite Seçimine Destek" />
      </Flex>
      <Flex p="4" flexDir={isMobile ? "column" : "row"}>
        <Text fontSize={isMobile ? "0.9rem" : "1.5rem"}>Bilgi için</Text>
        <Button
          size="none"
          variant="unstyled"
          cursor="pointer"
          _hover={{ opacity: 0.5 }}
          onClick={handleSendClick}
        >
          <Text
            fontSize={isMobile ? "0.9rem" : "1.5rem"}
            mx={isMobile ? "0" : "2"}
            color="purple.dark"
            textDecoration="underline"
          >
            info@uniwayapp.com
          </Text>
        </Button>
        <Text fontSize={isMobile ? "0.9rem" : "1.5rem"}>'a mail at !!</Text>
      </Flex>
    </Flex>
  );
};
