import { FC, SVGProps } from "react";

export const StarIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="26"
      height="24"
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M25.2769 13.5187C25.214 13.3929 25.0568 13.3301 24.9311 13.2986C24.208 13.2986 17.9831 13.0471 17.9831 5.81618C17.9831 5.5961 17.7944 5.40747 17.5744 5.40747C17.3543 5.40747 17.1657 5.5961 17.1657 5.81618C17.1657 13.0471 10.9408 13.2986 10.2491 13.2986C10.1548 13.2986 10.0605 13.3301 9.99762 13.3929C9.93474 13.4244 9.9033 13.4872 9.87187 13.5187C9.80899 13.6444 9.80899 13.8016 9.87187 13.9274C9.93474 14.0531 10.0919 14.1475 10.2491 14.1475C10.2491 14.1475 10.2491 14.1475 10.2806 14.1475C11.0037 14.1475 17.1971 14.399 17.1971 21.6299C17.1971 21.85 17.3857 22.0386 17.6058 22.0386C17.8259 22.0386 18.0145 21.85 18.0145 21.6299C18.0145 14.4304 24.208 14.1475 24.9311 14.1475C25.0883 14.1475 25.214 14.0531 25.3083 13.9274C25.3712 13.8016 25.3712 13.6444 25.2769 13.5187ZM17.5744 18.5489C16.6626 15.5622 14.4305 14.2732 12.6699 13.7073C14.4305 13.1414 16.6626 11.8524 17.5744 8.86574C18.4861 11.8524 20.7183 13.1414 22.4788 13.7073C20.7183 14.2732 18.4861 15.5622 17.5744 18.5489Z"
        fill="#F5C300"
      />
      <path
        d="M12.3867 5.3446C12.6067 5.3446 12.7954 5.15597 12.7954 4.90445C12.7954 4.68438 12.6067 4.49575 12.3867 4.49575C12.0094 4.49575 8.61401 4.37 8.61401 0.408705C8.61401 0.188633 8.42537 0 8.2053 0C7.98523 0 7.7966 0.188633 7.7966 0.408705C7.7966 4.37 4.4012 4.49575 4.02394 4.49575C3.9925 4.49575 3.9925 4.49575 3.9925 4.49575C3.77243 4.52719 3.61523 4.68438 3.61523 4.90445C3.61523 5.12453 3.80387 5.31316 4.02394 5.31316C4.43264 5.31316 7.7966 5.43891 7.7966 9.40021C7.7966 9.62028 7.98523 9.80891 8.2053 9.80891C8.39393 9.84035 8.58257 9.65172 8.58257 9.43164C8.58257 5.47035 11.978 5.3446 12.3552 5.3446H12.3867ZM8.17386 7.13661C7.67084 5.94194 6.75912 5.28172 5.91027 4.90445C6.82199 4.52719 7.67084 3.8041 8.17386 2.6723C8.67688 3.86697 9.58861 4.52719 10.4375 4.90445C9.52573 5.28172 8.67688 6.00481 8.17386 7.13661Z"
        fill="#F5C300"
      />
      <path
        d="M8.77143 18.6747C8.39416 18.6747 4.99877 18.5489 4.99877 14.5877C4.99877 14.3676 4.81014 14.179 4.59007 14.179C4.37 14.179 4.18136 14.3676 4.18136 14.5877C4.18136 18.5489 0.78597 18.6747 0.408705 18.6747C0.377266 18.6747 0.377266 18.6747 0.377266 18.6747C0.157194 18.7061 0 18.8633 0 19.0834C0 19.3035 0.188633 19.4921 0.408705 19.4921C0.817409 19.4921 4.18136 19.6179 4.18136 23.5792C4.18136 23.7992 4.37 23.9879 4.59007 23.9879C4.7787 24.0507 4.96733 23.8621 4.96733 23.6106C4.96733 19.6493 8.36272 19.5236 8.73999 19.5236H8.77143C8.9915 19.5236 9.18013 19.3349 9.18013 19.0834C9.18013 18.8633 8.9915 18.6747 8.77143 18.6747ZM4.55863 21.347C4.02417 20.1523 3.14388 19.4921 2.29503 19.1148C3.20676 18.7376 4.05561 18.0145 4.55863 16.8827C5.06165 18.0774 5.97337 18.7376 6.82222 19.1148C5.9105 19.4921 5.06165 20.2152 4.55863 21.347Z"
        fill="#F5C300"
      />
    </svg>
  );
};
