import { Button, Flex, Text, useMediaQuery } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
export const Header = () => {
  const isMobile = useMediaQuery("(max-width: 1200px)")[0];
  const navigate = useNavigate();

  return (
    <Flex
      borderRadius="6px"
      w={isMobile ? "100%" : "60%"}
      p="5"
      h="max-content"
      pos="relative"
      alignItems="center"
      justifyContent="center"
      bgColor="white"
      textAlign="center"
    >
      <Text
        fontWeight="600"
        fontSize={isMobile ? "1.2rem" : "2rem"}
        color="purple.dark"
        pos="absolute"
        left="5"
      >
        Uniway
      </Text>

      <Flex
        w="full"
        h="45px"
        alignItems="center"
        justifyContent={isMobile ? "end" : "center"}
        gap="4"
      >
        <Button
          variant="unstyled"
          size="none"
          p="2"
          _hover={{ bgColor: "purple.light" }}
          onClick={() => navigate("/about")}
        >
          <Text
            fontWeight="500"
            fontSize={isMobile ? "0.9rem" : "1.5rem"}
            color="purple.dark"
          >
            Hakkımızda
          </Text>
        </Button>
        {/* <Box w="2px" h="70%" bgColor="purple.light" /> */}
        {/* <Button
          variant="unstyled"
          size="none"
          p="2"
          _hover={{ bgColor: "purple.light" }}
          onClick={() => navigate("/contact")}
        >
          <Text
            fontWeight="500"
            fontSize={isMobile ? "0.9rem" : "1.5rem"}
            color="purple.dark"
          >
            İletişim
          </Text>
        </Button> */}
      </Flex>
    </Flex>
  );
};
