import { VerticalInfoBox } from "../components/veritcal-info-box";
import { Flex, Text, Box, useMediaQuery } from "@chakra-ui/react";

export const About = () => {
  const isMobile = useMediaQuery("(max-width: 1200px)")[0];

  return (
    <Flex bgColor="#ecf2ff" w="100vw" minH="100vh" p="5" flexDir="column">
      <Box>
        <Text
          fontWeight="600"
          fontSize={isMobile ? "1.5rem" : "2rem"}
          color="purple.dark"
        >
          Hakkımızda
        </Text>
      </Box>
      <Flex
        w="full"
        h="full"
        p="10"
        gap="10"
        flexDir="column"
        alignItems="center"
      >
        <VerticalInfoBox
          text="Uniway Nedir?"
          subtitle="YKS'ye hazırlanan öğrencilerin hedefledikleri okulda veya bölümde okuyan üniversite öğrencileriyle iletişim kurmalarını sağlayan bir uygulamadır Uniway, öğrencilerin sınav ve tercih süreçlerinde daha bilinçli kararlar verebilmelerini sağlamak amacıyla, hedefledikleri okullarda veya bölümlerde bulunan mentörlerle randevulu veya anlık görüşmeler yapmalarını mümkün kılar. Bu şekilde, öğrenciler, üniversite ve bölüm seçimleri hakkında deneyimli kişilerden destek alabilirler."
        />
        <VerticalInfoBox
          text="Uniway nasıl çalışır?"
          subtitle="Uniway, kullanıcıların hedefledikleri okul, bölüm ve sıralama kriterlerine göre kendilerine uygun mentörleri bulmalarına olanak tanır. Öğrenciler,tercihlerine uygun mentörleri sistem üzerinden seçebilir ve onlarla görüşme yapabilirler. Bu sayede öğrenciler kendi
hedeflerine yönelik destek alacaklar ve hedefledikleri üniversite ve bölümde okuyan mentöründen destek olarak sınav sürecinde motivasyon kazanacaklardir."
        />
        <VerticalInfoBox
          text="Uniway'i ne zaman kullanabilirim?"
          subtitle="Çoook Yakındaa!!"
        />
      </Flex>
    </Flex>
  );
};
