import { FC, SVGProps } from "react";

export const TickIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="full"
      height="full"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.5"
        y="0.5"
        width="35"
        height="35"
        rx="17.5"
        fill="#7183ee"
        fill-opacity="0.33"
      />
      <rect x="0.5" y="0.5" width="35" height="35" rx="17.5" stroke="#7183ee" />
      <path
        d="M26.6134 11.9197C26.0544 11.3599 25.1467 11.3603 24.587 11.9197L15.4669 21.0402L11.4133 16.9867C10.8536 16.427 9.94631 16.427 9.38659 16.9867C8.82687 17.5464 8.82687 18.4537 9.38659 19.0134L14.4533 24.0801C14.733 24.3598 15.0997 24.5 15.4665 24.5C15.8332 24.5 16.2003 24.3602 16.48 24.0801L26.6134 13.9464C27.1731 13.387 27.1731 12.4793 26.6134 11.9197Z"
        fill="#7183ee"
      />
    </svg>
  );
};
