import React from "react";
import { Flex, Text, Icon, useMediaQuery } from "@chakra-ui/react";
import { TickIcon } from "../assets/icons/tick-icon";

interface InfoBoxProps {
  icon?: React.ReactNode;
  text: string;
  subtitle?: string;
}

export const InfoBox: React.FC<InfoBoxProps> = ({
  icon = <TickIcon />,
  text,
}) => {
  const isMobile = useMediaQuery("(max-width: 1200px)")[0];

  return (
    <Flex
      h="120px"
      width="300px"
      p="4"
      py="6"
      border="6px"
      borderRadius="6px"
      bgColor="white.normal"
      align="center"
      pos="relative"
    >
      <Flex w="full" alignItems="center">
        <Icon width="32px" height="32px">
          {icon}
        </Icon>
        <Text fontSize={isMobile ? "0.8rem" : "1rem"} color="black" ml="4">
          {text}
        </Text>
      </Flex>

      {/* <Box w="90%">
        <Text fontSize="1rem" color="black" bgColor="red">
          {subtitle}
        </Text>
      </Box> */}
    </Flex>
  );
};
