import { extendTheme } from "@chakra-ui/react";
import { typography } from "./typography";
import { colors } from "./colors";

export const theme = extendTheme({
  fonts: typography,
  semanticTokens: { colors },
  styles: {
    global: {
      html: {
        fontSize: "100%",
      },
      body: {
        fontSize: "1rem",
        margin: 0,
        padding: 0,
      },
    },
  },
});
