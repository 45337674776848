import React from "react";
import { Flex, Text, Icon, useMediaQuery } from "@chakra-ui/react";
import { StarIcon } from "../assets/icons/star-icon";

interface VerticalInfoBoxProps {
  icon?: React.ReactNode;
  text: string;
  subtitle?: string;
}

export const VerticalInfoBox: React.FC<VerticalInfoBoxProps> = ({
  icon = <StarIcon />,
  text,
  subtitle,
}) => {
  const isMobile = useMediaQuery("(max-width: 1200px)")[0];

  return (
    <Flex
      h="max-content"
      width={isMobile ? "full" : "70%"}
      p="4"
      border="6px"
      borderRadius="6px"
      bgColor="white.normal"
      align="center"
      pos="relative"
      flexDir="column"
    >
      <Icon width="32px" height="32px">
        {icon}
      </Icon>

      <Flex w="full" alignItems="center" flexDir="column" my="2" gap="4">
        <Text
          fontSize={isMobile ? "1.2rem" : "1.5rem"}
          color="purple.dark"
          fontWeight="600"
          ml="4"
        >
          {text}
        </Text>
        <Text fontSize={isMobile ? "0.9rem" : "1rem"} color="#33373D">
          {subtitle}
        </Text>
      </Flex>
    </Flex>
  );
};
