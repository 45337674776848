export const colors = {
  purple: {
    light: "#ecf2ff",
    dark: "#7183ee",
  },
  white: {
    normal: "#ffffff",
  },
  gray: {
    light: "#D2D2D2",
  },
  yellow: {
    gold: "#FFD700",
  },
};
